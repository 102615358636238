import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`13th June 2019`}</p>


    <h2 {...{
      "id": "new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#new-features",
        "aria-label": "new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`You can now jump to a service method quickly using a go-to form in a `}<inlineCode parentName="p">{`DocService`}</inlineCode>{` debug page. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1815"
          }}>{`#1815`}</a></p>
        <img parentName="li" {...{
          "src": "https://user-images.githubusercontent.com/1866157/59041505-b6dd7100-88b3-11e9-87d5-edc826751620.gif",
          "width": 800
        }}></img>
      </li>
      <li parentName="ul">
        <p parentName="li">{`From this release, TLSv1.3 is enabled by default. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1779"
          }}>{`#1779`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`Route`}</inlineCode>{` replaces `}<inlineCode parentName="p">{`PathMapping`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1789"
          }}>{`#1789`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`ServerBuilder sb = new ServerBuilder();
sb.service(Route.builder()
                .path("/greet/{name}")
                .consumes(MediaType.JSON)
                .produces(MediaType.JSON_UTF_8)
                .build(),
           (ctx, req) -> HttpResponse.of(HttpStatus.OK,
                                         MediaType.JSON_UTF_8,
                                         ...));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`AggregatedHttpMessage`}</inlineCode>{` has been split into two subtypes: `}<inlineCode parentName="p">{`AggregatedHttpRequest`}</inlineCode>{` and `}<inlineCode parentName="p">{`AggregatedHttpResponse`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1702"
          }}>{`#1702`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1795"
          }}>{`#1795`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`The factory methods and some getter methods in `}<inlineCode parentName="li">{`AggregatedHttpMessage`}</inlineCode>{` have been pushed down to the two subtypes. For example, `}<inlineCode parentName="li">{`AggregatedHttpMessage.method()`}</inlineCode>{` does not exist anymore and you will find it in `}<inlineCode parentName="li">{`AggregatedHttpRequest`}</inlineCode>{`.`}</li>
          <li parentName="ul">{`We have revised the API to use `}<inlineCode parentName="li">{`AggregatedHttpRequest`}</inlineCode>{` or `}<inlineCode parentName="li">{`AggregatedHttpResponse`}</inlineCode>{` rather than `}<inlineCode parentName="li">{`AggregatedHttpMessage`}</inlineCode>{` wherever possible for clarity.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`New static factory methods have been added to `}<inlineCode parentName="p">{`HttpData`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1797"
          }}>{`#1797`}</a></p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><inlineCode parentName="p">{`HttpData.of()`}</inlineCode>{` was not very clear about whether it will wrap the specified bytes or make a copy of them. `}<inlineCode parentName="p">{`HttpData.wrap()`}</inlineCode>{` and `}<inlineCode parentName="p">{`HttpData.copyOf()`}</inlineCode>{` removes such ambiguity.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`byte[] b = new byte[] { 1, 2, 3 }
HttpData copied = HttpData.copyOf(b);
HttpData wrapped = HttpData.wrap(b);

b[0] = 0;
assert copied.array()[0] == 1;
assert wrapped.array()[0] == 0;
`}</code></pre>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now specify `}<inlineCode parentName="p">{`SubscriptionOption`}</inlineCode>{`s when subscribing to a `}<inlineCode parentName="p">{`StreamMessage`}</inlineCode>{` to modify the contract between `}<inlineCode parentName="p">{`StreamMessage`}</inlineCode>{` and `}<inlineCode parentName="p">{`Subscriber`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1700"
          }}>{`#1700`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1808"
          }}>{`#1808`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest req = ...;
// Subscriber.onError() will be invoked with a CancelledSubscriptionException
// when the Subscription is cancelled.
req.subscribe(mySubscriber, SubscriptionOption.NOTIFY_CANCELLATION);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The `}<inlineCode parentName="p">{`Endpoint`}</inlineCode>{`s loaded from a `}<inlineCode parentName="p">{`.properties`}</inlineCode>{` file by `}<inlineCode parentName="p">{`PropertiesEndpointGroup`}</inlineCode>{` are now automatically updated when the `}<inlineCode parentName="p">{`.properties`}</inlineCode>{` file is modified. Note that the classpath resources are not auto-reloaded and you have to specify a real file as a `}<inlineCode parentName="p">{`java.nio.Path`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1787"
          }}>{`#1787`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`PropertiesEndpointGroup endpoints = PropertiesEndpointGroup.of(
        Paths.get("/etc/my/endpoints.properties"),
        "endpoints.");
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now create a `}<inlineCode parentName="p">{`Client`}</inlineCode>{` by specifying an `}<inlineCode parentName="p">{`Endpoint`}</inlineCode>{` rather than a `}<inlineCode parentName="p">{`URI`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1743"
          }}>{`#1743`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Endpoint endpoint = Endpoint.of("example.com");
HttpClient client = new HttpClientBuilder(SessionProtocol.HTTPS, endpoint)
        ...
        .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now apply Armeria's default settings to an existing `}<inlineCode parentName="p">{`DropwizardMeterRegistry`}</inlineCode>{` (or `}<inlineCode parentName="p">{`PrometheusMeterRegistry`}</inlineCode>{`) using `}<inlineCode parentName="p">{`DropwizardMeterRegistries.configureRegistry()`}</inlineCode>{` (or `}<inlineCode parentName="p">{`PrometheusMeterRegistries.configureRegistry()`}</inlineCode>{`). Previously, Armeria's default settings were applied only to the `}<inlineCode parentName="p">{`MeterRegistry`}</inlineCode>{`s created by `}<inlineCode parentName="p">{`DropwizardMeterRegistries.newRegistry()`}</inlineCode>{` (or `}<inlineCode parentName="p">{`PrometheusMeterRegistries.newRegistry()`}</inlineCode>{`). `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1814"
          }}>{`#1814`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1820"
          }}>{`#1820`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import io.micrometer.jmx.JmxMeterRegistry;

// JmxMeterRegistry is a subtype of DropwizardMeterRegistry.
JmxMeterRegistry jmxRegistry = new JmxMeterRegistry(...);
DropwizardMeterRegistries.configureRegistry(jmxRegistry);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A new exception type `}<inlineCode parentName="p">{`InvalidSamlRequestException`}</inlineCode>{` has been added so that you can tell if a client sent an invalid SAML request from exception type. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1780"
          }}>{`#1780`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1783"
          }}>{`#1783`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#improvements",
        "aria-label": "improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Consolidated the logic related with TLS and improved TLS configuration validation. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1779"
        }}>{`#1779`}</a></li>
      <li parentName="ul">{`Stack trace is logged for better debuggability when `}<inlineCode parentName="li">{`RequestContextCurrentTraceContext`}</inlineCode>{` cannot find the current `}<inlineCode parentName="li">{`RequestContext`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1800"
        }}>{`#1800`}</a></li>
    </ul>
    <h2 {...{
      "id": "bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#bug-fixes",
        "aria-label": "bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`The percent-encoding of the characters such as `}<inlineCode parentName="li">{`#`}</inlineCode>{` and `}<inlineCode parentName="li">{`/`}</inlineCode>{` are now preserved correctly. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1805"
        }}>{`#1805`}</a></li>
      <li parentName="ul">{`gRPC `}<inlineCode parentName="li">{`Metadata`}</inlineCode>{` is now supported. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1788"
        }}>{`#1788`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1790"
        }}>{`#1790`}</a></li>
      <li parentName="ul">{`Fixed a bug where a bad gRPC client is created when a user specified a URL that does not end with a slash (`}<inlineCode parentName="li">{`/`}</inlineCode>{`). `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1785"
        }}>{`#1785`}</a></li>
      <li parentName="ul">{`The trailers added with `}<inlineCode parentName="li">{`ServiceRequestContext.addAdditionalResponseTrailer()`}</inlineCode>{` are not ignored anymore. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1782"
        }}>{`#1782`}</a></li>
      <li parentName="ul">{`JUnit 5 extensions are now initialized and destroyed on `}<inlineCode parentName="li">{`BeforeAll`}</inlineCode>{` and `}<inlineCode parentName="li">{`AfterAll`}</inlineCode>{` rather than on `}<inlineCode parentName="li">{`Each`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1801"
        }}>{`#1801`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`spring-boot-webflux-starter`}</inlineCode>{` does not fail to start anymore even if more than one `}<inlineCode parentName="li">{`MeterRegistry`}</inlineCode>{` exist. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1791"
        }}>{`#1791`}</a></li>
    </ul>
    <h2 {...{
      "id": "deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#deprecations",
        "aria-label": "deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deprecations`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HttpData.offset()`}</inlineCode>{` has been deprecated. It always returns `}<inlineCode parentName="li">{`0`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1771"
        }}>{`#1771`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1797"
        }}>{`#1797`}</a></li>
      <li parentName="ul">{`Some `}<inlineCode parentName="li">{`HttpData.of()`}</inlineCode>{` methods have been deprecated in favor of `}<inlineCode parentName="li">{`HttpData.wrap()`}</inlineCode>{` and/or `}<inlineCode parentName="li">{`HttpData.copyOf()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1797"
        }}>{`#1797`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`trailingHeaders()`}</inlineCode>{` methods in `}<inlineCode parentName="li">{`AggregatedHttpMessage`}</inlineCode>{` and `}<inlineCode parentName="li">{`HttpResult`}</inlineCode>{` have been deprecated in favor of `}<inlineCode parentName="li">{`trailers()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1795"
        }}>{`#1795`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`StreamMessage`}</inlineCode>{` methods which accept `}<inlineCode parentName="li">{`boolean withPooledObjects`}</inlineCode>{` have been deprecated. Use the methods that accept `}<inlineCode parentName="li">{`SubscriptionOption`}</inlineCode>{` and specify `}<inlineCode parentName="li">{`SubscriptionOption.WITH_POOLED_OBJECTS`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1700"
        }}>{`#1700`}</a></li>
    </ul>
    <h2 {...{
      "id": "breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#breaking-changes",
        "aria-label": "breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`AggregatedHttpMessage`}</inlineCode>{` has been split into two subtypes. See 'New features' for more information. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1702"
        }}>{`#1702`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1795"
        }}>{`#1795`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`PathMapping`}</inlineCode>{` and its related classes have been replaced with `}<inlineCode parentName="li">{`Route`}</inlineCode>{` and its related classes. See 'New features' for more information. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1789"
        }}>{`#1789`}</a>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`ServiceWithPathMappings`}</inlineCode>{` has been replaced with `}<inlineCode parentName="li">{`ServiceWithRoutes`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ClientCacheControl`}</inlineCode>{` and `}<inlineCode parentName="li">{`ServerCacheControl`}</inlineCode>{` have been moved to the `}<inlineCode parentName="li">{`com.linecorp.armeria.common`}</inlineCode>{` package. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1813"
        }}>{`#1813`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1816"
        }}>{`#1816`}</a></li>
    </ul>
    <h2 {...{
      "id": "dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dependencies",
        "aria-label": "dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Bouncy Castle 1.61 -> 1.62`}</li>
      <li parentName="ul">{`gRPC 1.20.0 -> 1.21.0`}</li>
      <li parentName="ul">{`Guava 27.1 -> 28.0`}</li>
      <li parentName="ul">{`java-jwt 3.8.0 -> 3.8.1`}</li>
      <li parentName="ul">{`Project Reactor 3.2.9 -> 3.2.10`}</li>
      <li parentName="ul">{`protobuf-jackson 0.3.1 -> 0.4.0`}</li>
      <li parentName="ul">{`Retrofit 2.5.0 -> 2.6.0`}</li>
      <li parentName="ul">{`RxJava 2.2.8 -> 2.2.9`}</li>
      <li parentName="ul">{`Tomcat 9.0.20 -> 9.0.21, 8.5.40 -> 8.5.42`}</li>
      <li parentName="ul">{`ZooKeeper 3.4.14 -> 3.5.5`}</li>
    </ul>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'dawnbreaks', 'delegacy', 'hyangtack', 'ikhoon', 'imasahiro', 'jrhee17', 'minwoox', 'RyouZhang', 'SeungukHan', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      